import React from 'react'
import { Zoom, Slide } from "react-awesome-reveal"

import SkillsItem from '../components/SkillsItem'

import styles from './Skills.module.css'

import adj from '../img/adj.mp4'
import sik from '../img/sik.png'
import spacedude from '../img/spacedude.png'

const SkillsPage = () => {
  window.scrollTo(0, 0)

  const programming = ['react', 'html5', 'css', 'node.js', 'python', 'c++', 'c', 'java', 'groovy']
  const tools = ['git', 'sql', 'xml', 'linux', 'soap', 'mongoDB', 'jenkins', 'matlab']

  const listProgramming = programming.map((language) => 
    <li key={language}>{language}</li>
  )

  const listTools = tools.map((tool) => 
    <li key={tool} style={{marginBottom: '0.19em'}}>{tool}</li>
  )

  return (
    <div className={styles.skills_container}>
      <Slide direction='left' triggerOnce>
        <div className={styles.skills_header}>
          <h3>these I've worked with</h3>
        </div>
      </Slide>
      <div className={styles.skills}>
        <Slide direction='left' triggerOnce>
          <ul>{listProgramming}</ul>
        </Slide>
        <Slide direction='right' triggerOnce>
          <ul>{listTools}</ul>
        </Slide>
      </div>
      <Slide direction='right' triggerOnce>
        <div className={styles.skills_header}>
          <h3>here's something I've done</h3>
        </div>
      </Slide>
      <div className={styles.porftolio_items}>
        <Zoom triggerOnce>
          <SkillsItem
            directory={adj}
            type='video'
            url='https://wizardly-nobel-dff0df.netlify.app/'
            title='AaltoDJ'
            text='I designed and created a new webpage for AaltoDJ with React'
          />
        </Zoom>
        <Zoom triggerOnce>
          <SkillsItem
            directory={sik}
            type='image'
            url='https://sahkoinsinoorikilta.fi'
            title='sikweb 2.0'
            text='I participarted in creating a new webpage for our student association. Stack included React, TS, Angular, Django'
          />
        </Zoom>
        <Zoom triggerOnce>
          <SkillsItem
            directory={spacedude}
            type='image'
            title='Spacedude 3'
            text='Me and a few friends created a top-down shooter game that has 2 different game modes, multiple maps, self-drawn graphics and self-composed music. The game was created with C++ and SFML library'
          />
        </Zoom>
      </div>
    </div>
  )
}

export default SkillsPage
