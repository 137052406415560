import React from 'react'
import { Zoom, Slide } from "react-awesome-reveal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faTelegram, faInstagramSquare, faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

import styles from './Contact.module.css'

const ContactPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Slide direction='left'>
          <h3>Want to contact me?</h3>
        </Slide>
      </div>
      <div className={styles.icons_container}>
        <Zoom>
          <a href='https://www.linkedin.com/in/tonilyttinen/'>
            <FontAwesomeIcon className={styles.icon} icon={ faLinkedin }/>
          </a>
          <a href='https://t.me/ToniLyttinen'>
            <FontAwesomeIcon className={styles.icon} icon={ faTelegram }/>
          </a>
          <a href='https://www.instagram.com/devcristian/'>
            <FontAwesomeIcon className={styles.icon} icon={ faInstagramSquare }/>
          </a>
          <a href='https://www.facebook.com/toni.lyttinen.9'>
            <FontAwesomeIcon className={styles.icon} icon={ faFacebookSquare }/>
          </a>
        </Zoom>
      </div>
      <div className={styles.header}>
        <Slide direction='right'>
          <h3>contact(ät)tonilyttinen.fi</h3>
        </Slide>
      </div>
    </div>
  )
}

export default ContactPage
