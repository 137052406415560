import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './App.css'

import NavBar from './components/NavBar'
import HomePage from './pages/index'
import SkillsPage from './pages/Skills'
import ContactPage from './pages/Contact'

const App = () => {
  return (
    <div className="container">
      <NavBar/>
      <Switch>
        <Route path='/' exact component={HomePage}/>
        <Route path='/skills' component={SkillsPage}/>
        <Route path='/contact' component={ContactPage}/>
      </Switch>
    </div>
  );
}

export default App;
