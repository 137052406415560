import React from 'react'
import { Link } from 'react-router-dom'

import me from '../img/me.png'

import styles from './index.module.css'

const HomePage = () => {
  window.scrollTo(0, 0)
  return (
    <div className={styles.home_container}>
      <div className={styles.header}>
        <img className={styles.header_img} alt='me' src={me}/>
        <div>
          <div className={styles.header_title}>
            <h3 className={styles.h3}>about me</h3>
          </div>
          <div className={styles.header_text}>
            <p>I'm a 28 year old developer from Otaniemi studying Human-Computer Interaction. On my free time I like to do web apps and play video games. I also enjoy craft beer.</p>
            <p>I've been working on multiple apps and projects using various tools and programming languages. You can read more about my skills on the <Link to='/skills' className={styles.link}>skills page</Link></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage