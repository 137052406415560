import React from 'react'
import { NavLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
  useMediaQuery
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.0)',
    overflow: 'hidden',
    paddingLeft: `calc(100vw - 100%)`
  },
  title: {
    flexGrow: 1,
    fontFamily: 'sora',
    fontSize: '20px',
    fontWeight: '1000',
    letterSpacing: '2px',
    textShadow: '1px 1px #000000',
    color: '#ffffff',
    textSpacing: '15px',
  },
  link: {
    marginLeft: '1px',
    marginRight: '1px',
    background: '#302f2f',
    textDecoration: 'none',
    padding: '15px',
    color: '#ffffff',
    '&:hover': {
      color: '#4ef9ff',
      textShadow: '2px 2px #000000',
    }
  },
  tiny_link: {
    marginLeft: '1px',
    marginRight: '1px',
    background: '#302f2f',
    textDecoration: 'none',
    padding: '7px',
    color: '#ffffff',
    '&:hover': {
      color: '#4ef9ff',
      textShadow: '2px 2px #000000',
    }
  }
}))

const NavBar = () => {
  const classes = useStyles()
  const trigger = useScrollTrigger()
  const tiny_screen = useMediaQuery('(min-width:328px)')

  return (
    <Slide appear={false} direction={'down'} in={!trigger}>
      <AppBar className={classes.navbar} elevation={0}>
        <Toolbar variant='dense'>
          <Typography variant='h6' className={classes.title}>
            <NavLink className={tiny_screen ? classes.link : classes.tiny_link} to='/'>
              home
            </NavLink>
            <NavLink className={tiny_screen ? classes.link : classes.tiny_link} to='/skills'>
              skills
            </NavLink>
            <NavLink className={tiny_screen ? classes.link : classes.tiny_link} to='/contact'>
              contact
            </NavLink>
          </Typography>
        </Toolbar>
      </AppBar>
    </Slide>
  )
}

export default NavBar