import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60vw',
    marginBottom: '11vh',
  },
  root: {
    display: 'inline-block',
    borderRadius: '20px'
  },
  media: {
    objectFit: 'contain',
    display: 'flex',
    transform: `scale(${1.01})`,
    width: '60vw'
  },
  text_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffffff'
  },
  title: {
    fontFamily: 'sora',
    fontWeight: '1000',
    color: '#302f2f'
  },
  text: {
    fontFamily: 'sora',
    color: '#302f2f',
    fontWeight: '600'
  },
  divider: {
    width: '55vw',
    color: '#302f2f',
    height: '2px',
    marginTop: '-1.5vh',
    marginBottom: '1vh'
  }
});

const SkillsCard = ({directory, type, url, title, text}) => {
  const classes = useStyles();
  
  if (type === 'video') {
    return(
      <Box className={classes.card}>
      <Card classesName={classes.root} style={{borderRadius: '0px'}}>
          <a href={url}>
            <CardMedia className={classes.media} component='video' src={directory} autoPlay loop='loop' muted='muted'/>
          </a>
          <CardContent className={classes.text_container}>
            <Divider className={classes.divider} variant="middle" />
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className={classes.text} variant="body2" color="textSecondary" component="p">
              {text}
            </Typography>
          </CardContent>
      </Card>
    </Box>
    )
  } else {
    return(
      <Box className={classes.card}>
        <Card classesName={classes.root} style={{borderRadius: '0px'}}>
          <a href={url}>
            <CardMedia className={classes.media} component='img' image={directory}/>
          </a>
          <CardContent className={classes.text_container}>
            <Divider className={classes.divider} variant="middle" />
            <Typography className={classes.title} gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className={classes.text} variant="body2" color="textSecondary" component="p">
              {text}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    )
  }
}
  
export default SkillsCard